/* global envName, serverHost, codePrefix */
!(function (x, n) {
  window[n] = window[n] || {};
  window[n].config = {
    appId: 'C49090620DD44DFD8E3CA5911408CBBE',
    setting: 'api,jsTrack,uba,longtask,rtti,fps',
    hashMode: true,
    smartJsErr: false
  };
  let o = document.createElement('script');
  o.src = x;
  o.async = !0;
  let d = document.body.firstChild;
  document.body.insertBefore(o, d);
})('https://res.hc-cdn.com/FurionSdkStatic/3.6.11/furion-cdn.min.js', '__fr');

import Xtemplate from 'xtemplate-runtime';
import $ from 'zepto-webpack';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import './index.less';
import './footer.less';
import './user.less';
import './theme.less';
import headerTpl from './header.xtpl';
import footerTpl from './footer.xtpl';
import userTpl from './user.xtpl';
import footerJson from './footer.json';
import headerJson from './header.json';
import subLogoJson from './subLogo.json';
import helpDocsJson from './helpDocs.json';
import codeUrlJson from './codeUrl.json';

// 注册全局事件  用来监听popstate
const windowHistoryWrap = function (type) {
  const orig = history[type];
  const e = new Event(type);
  return function (...args) {
    const rv = orig.apply(this, args);
    e.arguments = args;
    window.dispatchEvent(e);
    return rv;
  };
};
history.pushState = windowHistoryWrap('pushState');
history.replaceState = windowHistoryWrap('replaceState');

export default class TinyUIDesignCommon {
  envName;
  constructor(ids, options) {
    const { menus = [], title = 'OpenTiny', logoLink = '/', showTheme = false, menuCollapse, helpdocs = '', subLogoOptions } = options;
    const quickMenus = headerJson[envName];
    const { footerbrandInfo, promotional, footerLogolist } = footerJson;
    const { subLogo, subName, logoClass } = subLogoOptions || this.getSubLogo();
    const codeUrl = this.getCodeUrl();
    const barcodeUrl = envName === 'open' ? './assets/code-open.png' : './assets/code-inner.png';
    const header = ids.find(item => item.match(/header/i));
    const footer = ids.find(item => item.match(/footer/i));

    this.$header = $(header);
    this.$footer = $(footer);
    this.menuCollapse = menuCollapse;
    this.helpdocs = helpdocs || this.getHelpDocs();
    this.header = new Xtemplate(headerTpl).render({
      menuCollapse,
      menus,
      subLogo,
      subName,
      logoClass,
      title,
      logoLink,
      showTheme,
      quickMenus,
      helpdocs,
      codeUrl
    });
    this.footer = new Xtemplate(footerTpl).render({
      data: footerJson[envName],
      barcodeUrl,
      footerbrandInfo,
      promotional,
      footerLogolist
    });
    this.themeSubject = new BehaviorSubject('light');
  }

  async renderHeader() {
    // 如果页面已经有header，不重复渲染header
    if (document.getElementsByClassName('tinyui-design-header')[0]) {
      this.destoryHeader();
    }

    this.$header.append(this.header);
    this.bindEvent();

    const response = await this.getUserInfo();
    this.renderLogin(response.data || {});
    this.bindUserEvent();
  }

  async renderFooter() {
    // 如果页面已经有footer，不重复渲染footer
    if (document.getElementsByClassName('tinyui-design-footer')[0]) {
      this.destoryFooter();
    }

    this.$footer.append(this.footer);
    this.bindFooterEvent();
  }

  renderLogin(result) {
    this.isLogin = Boolean(Object.keys(result).length);
    const emailImgArr = ['userIcon_1.png', 'userIcon_2.png', 'userIcon_3.png'];
    const userResult = new Xtemplate(userTpl).render({
      isLogin: this.isLogin,
      userIcon: emailImgArr.indexOf(result.userIcon) < 0 ? result.userIcon : `./assets/${result.userIcon}`
    });
    if (this.isLogin && location.href.indexOf('tiny-login/login') !== -1) {
      location.href = location.origin;
    }

    $('.user-nav-menus').html(userResult);
  }

  render() {
    this.renderHeader();
    this.renderFooter();
  }

  destoryHeader() {
    this.$header.html('');
  }

  destoryFooter() {
    this.$footer.html('');
  }

  destory() {
    this.destoryHeader();
    this.destoryFooter();
  }

  getUserInfo() {
    return new Promise((resovle, reject) => {
      $.ajax({
        url: `${serverHost}designtheme/api/user/userInfo`,
        type: 'get',
        xhrFields: {
          // 允许跨域带Cookie
          withCredentials: true
        },
        success: result => {
          resovle(result);
        },
        error: function (err) {
          const { status, response } = err;
          if (status === 401) {
            // 通过重定向去除code
            location.href = `${JSON.parse(response)['x-login-url']}`;
          }
          reject(err);
        }
      });
    });
  }

  loginReq() {
    $.ajax({
      url: `${serverHost}designtheme/api/user/login?thirdLoginType=third-platform`,
      type: 'get',
      xhrFields: {
        // 允许跨域带Cookie
        withCredentials: true
      },
      success: () => {
        // 成功回调
        this.renderLogin({});
        this.bindUserEvent();
      },
      error: err => {
        const { status, response } = err;
        if (status === 401) {
          // 未登录态
          location.href = `${JSON.parse(response)['x-login-url']}`;
        }
      }
    });
  }

  logoutReq() {
    $.ajax({
      url: `${serverHost}designtheme/api/user/logout`,
      type: 'get',
      xhrFields: {
        // 允许跨域带Cookie
        withCredentials: true
      },
      success: () => {
        // 退出成功回调
        this.renderLogin({});
        this.bindUserEvent();
      },
      error: err => {
        return;
      }
    });
  }

  bindEvent() {
    this.$header.find('.theme-common').click(event => {
      const curTarget = $(event.currentTarget);
      const activeEls = curTarget.siblings('.theme-active');

      if (activeEls.length) {
        activeEls.removeClass('theme-active');
      }
      curTarget.addClass('theme-active');

      const type = curTarget.attr('class').indexOf('light') !== -1 ? 'light' : 'dark';
      this.themeSubject.next(type);
    });

    this.switchDrop('.menu', '.menu-dropdown', 'menu-dropdown-show');
    this.switchDrop('.user-nav-menus', '.menu-dropdown', 'menu-dropdown-show');
    this.switchDrop('.menu-language', '.menu-dropdown', 'menu-dropdown-show');

    // 815屏幕 点击隐藏展示小菜单
    const _this = this;
    _this.$header.find('.collapse-menu img').on('click', () => {
      _this.$header.find('.nav .collapse-nav').toggleClass('toggleNav');
    });

    // 点击空白区域隐藏左右侧菜单
    _this.$header.siblings().on('click', event => {
      _this.$header.find('.nav .collapse-nav').removeClass('toggleNav');
      if (this.menuCollapse?.useCollapse && !event.target.closest(`${this.menuCollapse.menuId || ''}`)) {
        // 如果使用折叠菜单，点击除了菜单以外的空白区域隐藏菜单，以防点击二级菜单时隐藏
        this.menuCollapse?.useCollapse && $(this.menuCollapse.menuId).removeClass('showMenu');
      }
    });

    // 使用折叠菜单
    if (this.menuCollapse?.useCollapse) {
      setTimeout(() => {
        // 异步任务绑定事件，以防菜单dom未挂载
        $(this.menuCollapse.menuId).addClass('hideMenu');
        $('#menuCollapse').on('click', () => {
          $(this.menuCollapse.menuId).toggleClass('showMenu');
        });

        // 监听popstate变化 隐藏菜单
        window.addEventListener('pushState', () => {
          $(this.menuCollapse.menuId).removeClass('showMenu');
        });
        window.addEventListener('popState', () => {
          $(this.menuCollapse.menuId).removeClass('showMenu');
        });
        window.addEventListener('replaceState', () => {
          $(this.menuCollapse.menuId).removeClass('showMenu');
        });
      });
    }
  }

  bindFooterEvent() {
    const _this = this;

    // 页脚展开收起二级菜单
    _this.$footer.find('.footer-title-container').on('click', function (event) {
      $(this).toggleClass('current').siblings('.footer-text-list').toggleClass('current');
    });
  }

  bindUserEvent() {
    // 登录
    this.$header.find('.login-user-logout').click(() => {
      this.logoutReq();
    });

    // 退出
    this.$header.find('.login-user-text').click(() => {
      if (envName === 'inner') {
        this.loginReq();
      } else {
        location.href = `${location.origin}/tiny-login/login`;
      }
    });
  }

  switchDrop(parentEleClass, targetEleClass, showClass) {
    this.$header
      .find(`${parentEleClass}`)
      .mouseenter(event => {
        $(event.currentTarget).find(`${targetEleClass}`).addClass(`${showClass}`);
      })
      .mouseleave(event => {
        $(event.currentTarget).find(`${targetEleClass}`).removeClass(`${showClass}`);
      });
  }

  // 副标题相关逻辑
  getSubLogo() {
    const keys = Object.keys(subLogoJson);
    let result = {};
    keys.forEach(key => {
      subLogoJson[key].keys.forEach(item => {
        if (location.pathname.indexOf(item) > -1) {
          result = {
            subLogo: subLogoJson[key].subLogo,
            subName: subLogoJson[key].subName,
            logoClass: subLogoJson[key].logoClass
          };
        }
      });
    });

    return result;
  }

  // 帮助文档相关逻辑
  getHelpDocs() {
    const keys = Object.keys(helpDocsJson);
    let result;
    keys.forEach(key => {
      const pathname = helpDocsJson[key].pathname;

      // 不需要显示帮助文档的相关地址
      const ignore = helpDocsJson[key].ignore;
      helpDocsJson[key].keys.forEach(item => {
        if ((pathname && location.pathname === pathname) || (item.length > 1 && location.pathname.startsWith(item) && !location.pathname.startsWith(ignore))) {
          result = helpDocsJson[key].url;
        }
      });
    });

    return result;
  }

  // 代码地址相关逻辑
  getCodeUrl() {
    const keys = Object.keys(codeUrlJson);
    let result = `${codePrefix}${codeUrlJson.tinyvue.url[envName]}`;
    keys.forEach(key => {
      codeUrlJson[key].keys.forEach(item => {
        if (location.pathname.indexOf(item) > -1) {
          result = `${codePrefix}${codeUrlJson[key].url[envName]}`;
        }
      });
    });

    return result;
  }
}
