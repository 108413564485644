
  
   
  
  var ret = function (undefined){
  var t;
var t0;
var t1;
var t2;
var t3;
var t4;
var t5;
var t6;
var t7;
var t8;
var t9;
var tpl = this;
  var root = tpl.root;
  var buffer = tpl.buffer;
  var scope = tpl.scope;
  var runtime = tpl.runtime;
  var name = tpl.name;
  var pos = tpl.pos;
  var data = scope.data;
  var affix = scope.affix;
  var nativeCommands = root.nativeCommands;
  var utils = root.utils;
var callFnUtil = utils["callFn"];
var callDataFnUtil = utils["callDataFn"];
var callCommandUtil = utils["callCommand"];
var rangeCommand = nativeCommands["range"];
var voidCommand = nativeCommands["void"];
var foreachCommand = nativeCommands["foreach"];
var forinCommand = nativeCommands["forin"];
var eachCommand = nativeCommands["each"];
var withCommand = nativeCommands["with"];
var ifCommand = nativeCommands["if"];
var setCommand = nativeCommands["set"];
var includeCommand = nativeCommands["include"];
var includeOnceCommand = nativeCommands["includeOnce"];
var parseCommand = nativeCommands["parse"];
var extendCommand = nativeCommands["extend"];
var blockCommand = nativeCommands["block"];
var macroCommand = nativeCommands["macro"];
var debuggerCommand = nativeCommands["debugger"];
function func0(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n      <div class="user-avatar">\n        <img src="';
pos.line = 5;
var id1 = ((t=(affix.userIcon)) !== undefined ? t : ((t = data.userIcon) !== undefined ? t  : scope.resolveLooseUp(["userIcon"])));
buffer = buffer.writeEscaped(id1);
buffer.data += '" />\n      </div>\n      <div class="menu-dropdown">\n        <img class="user-avatar" src="';
pos.line = 8;
var id2 = ((t=(affix.userIcon)) !== undefined ? t : ((t = data.userIcon) !== undefined ? t  : scope.resolveLooseUp(["userIcon"])));
buffer = buffer.writeEscaped(id2);
buffer.data += '" />\n        <div class="line"></div>\n        <div class="menu-dropdown-item login-user-logout">退出登录</div>\n      </div>\n    ';
return buffer;
}
function func3(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n      <div class="login-user-text">登录</div>\n    ';
return buffer;
}


buffer.data += '\n  <a class="menu menu-2 user-menu">\n    ';
pos.line = 3;
pos.line = 3;
var id4 = ((t=(affix.isLogin)) !== undefined ? t : ((t = data.isLogin) !== undefined ? t  : scope.resolveLooseUp(["isLogin"])));
buffer = ifCommand.call(tpl, scope, {  params: [ id4 ],fn: func0,inverse: func3 }, buffer);
buffer.data += '\n  </a>';
return buffer;
};
  
  ret.TPL_NAME = 'user.xtpl';
  
  export default ret;