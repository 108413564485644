
  
   
  
  var ret = function (undefined){
  var t;
var t0;
var t1;
var t2;
var t3;
var t4;
var t5;
var t6;
var t7;
var t8;
var t9;
var tpl = this;
  var root = tpl.root;
  var buffer = tpl.buffer;
  var scope = tpl.scope;
  var runtime = tpl.runtime;
  var name = tpl.name;
  var pos = tpl.pos;
  var data = scope.data;
  var affix = scope.affix;
  var nativeCommands = root.nativeCommands;
  var utils = root.utils;
var callFnUtil = utils["callFn"];
var callDataFnUtil = utils["callDataFn"];
var callCommandUtil = utils["callCommand"];
var rangeCommand = nativeCommands["range"];
var voidCommand = nativeCommands["void"];
var foreachCommand = nativeCommands["foreach"];
var forinCommand = nativeCommands["forin"];
var eachCommand = nativeCommands["each"];
var withCommand = nativeCommands["with"];
var ifCommand = nativeCommands["if"];
var setCommand = nativeCommands["set"];
var includeCommand = nativeCommands["include"];
var includeOnceCommand = nativeCommands["includeOnce"];
var parseCommand = nativeCommands["parse"];
var extendCommand = nativeCommands["extend"];
var blockCommand = nativeCommands["block"];
var macroCommand = nativeCommands["macro"];
var debuggerCommand = nativeCommands["debugger"];
function func4(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += ' \n          <div class="footer-promotional-label">';
pos.line = 10;
var id5 = ((t=(affix.label)) !== undefined ? t : ((t = data.label) !== undefined ? t  : scope.resolveLooseUp(["label"])));
buffer = buffer.writeEscaped(id5);
buffer.data += '</div>\n        ';
return buffer;
}
function func7(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n            <a href="';
pos.line = 14;
var id8 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id8);
buffer.data += '" class="footer-logo-link footer-logo-link-list">\n              <img src="';
pos.line = 15;
var id9 = ((t=(affix.logo)) !== undefined ? t : ((t = data.logo) !== undefined ? t  : scope.resolveLooseUp(["logo"])));
buffer = buffer.writeEscaped(id9);
buffer.data += '" title="';
var id10 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id10);
buffer.data += '"/>\n            </a>\n          ';
return buffer;
}
function func15(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n            <a href="';
pos.line = 33;
var id16 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id16);
buffer.data += '" title="';
var id17 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id17);
buffer.data += '" target="_blank" rel="noopener noreferrer" class="list-item">';
var id18 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id18);
buffer.data += '</a>\n          ';
return buffer;
}
function func12(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n      <div class="link-item">\n        <div class="footer-title-container">\n          <img src="';
pos.line = 27;
var id13 = ((t=(affix.icon)) !== undefined ? t : ((t = data.icon) !== undefined ? t  : scope.resolveLooseUp(["icon"])));
buffer = buffer.writeEscaped(id13);
buffer.data += '" class="footer-logo-s"/>\n          <span class="footer-title">';
pos.line = 28;
var id14 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id14);
buffer.data += '</span>\n          <span class="icon-arrow"></span>\n        </div>\n        <div class="footer-text-list">\n          ';
pos.line = 32;
pos.line = 32;
var id19 = ((t=(affix.links)) !== undefined ? t : ((t = data.links) !== undefined ? t  : scope.resolveLooseUp(["links"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id19 ],fn: func15 }, buffer);
buffer.data += '          \n        </div>        \n      </div>\n      ';
return buffer;
}


buffer.data += '<div class="tinyui-design-footer">\n  <div class="footer-content">\n   <div class="footer-logo-container">\n      <a href="';
pos.line = 4;
var id0 = ((t=(affix.footerbrandInfo)) !== undefined ? (t != null ? (t0 = t.href) : t) : ((t = data.footerbrandInfo) !== undefined ? (t != null ? (t0 = t.href) : t)  : scope.resolveLooseUp(["footerbrandInfo","href"])));
buffer = buffer.writeEscaped(id0);
buffer.data += '" class="footer-logo-link">\n        <img src="';
pos.line = 5;
var id1 = ((t=(affix.footerbrandInfo)) !== undefined ? (t != null ? (t0 = t.logo) : t) : ((t = data.footerbrandInfo) !== undefined ? (t != null ? (t0 = t.logo) : t)  : scope.resolveLooseUp(["footerbrandInfo","logo"])));
buffer = buffer.writeEscaped(id1);
buffer.data += '" title="';
var id2 = ((t=(affix.footerbrandInfo)) !== undefined ? (t != null ? (t0 = t.title) : t) : ((t = data.footerbrandInfo) !== undefined ? (t != null ? (t0 = t.title) : t)  : scope.resolveLooseUp(["footerbrandInfo","title"])));
buffer = buffer.writeEscaped(id2);
buffer.data += '" class="footer-logo"/>\n        <span class="footer-logo-title">';
pos.line = 6;
var id3 = ((t=(affix.footerbrandInfo)) !== undefined ? (t != null ? (t0 = t.text) : t) : ((t = data.footerbrandInfo) !== undefined ? (t != null ? (t0 = t.text) : t)  : scope.resolveLooseUp(["footerbrandInfo","text"])));
buffer = buffer.writeEscaped(id3);
buffer.data += '</span>\n      </a>\n      <div class="footer-promotional-container">\n        ';
pos.line = 9;
pos.line = 9;
var id6 = ((t=(affix.promotional)) !== undefined ? t : ((t = data.promotional) !== undefined ? t  : scope.resolveLooseUp(["promotional"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id6 ],fn: func4 }, buffer);
buffer.data += '\n        <div class="footer-logolist-container">\n          ';
pos.line = 13;
pos.line = 13;
var id11 = ((t=(affix.footerLogolist)) !== undefined ? t : ((t = data.footerLogolist) !== undefined ? t  : scope.resolveLooseUp(["footerLogolist"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id11 ],fn: func7 }, buffer);
buffer.data += '\n        </div>\n      </div>\n      \n   </div>\n    \n    <div class="links">\n      ';
pos.line = 24;
pos.line = 24;
var id20 = ((t=(affix.data)) !== undefined ? t : ((t = data.data) !== undefined ? t  : scope.resolveLooseUp(["data"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id20 ],fn: func12 }, buffer);
buffer.data += '\n      <div class="contact">\n        <div>\n          <img src="./assets/footer-title-human.svg" class="footer-logo-s"/>\n          <span class="footer-title">联系我们</span>\n        </div>\n        <div class="group-code">\n          <img src="';
pos.line = 44;
var id21 = ((t=(affix.barcodeUrl)) !== undefined ? t : ((t = data.barcodeUrl) !== undefined ? t  : scope.resolveLooseUp(["barcodeUrl"])));
buffer = buffer.writeEscaped(id21);
buffer.data += '"/>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="copyright">\n  <p>Copyright © Huawei Technologies Co., Ltd. 2023. All rights reserved.</p>\n  <p>粤ICP备2022156931号-1</p>\n  </div>\n</div>';
return buffer;
};
  
  ret.TPL_NAME = 'footer.xtpl';
  
  export default ret;