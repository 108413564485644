
  
  import esDep86 from "./user.xtpl";
import esDep52 from "./user.xtpl"; 
  
  var ret = function (undefined){
  var t;
var t0;
var t1;
var t2;
var t3;
var t4;
var t5;
var t6;
var t7;
var t8;
var t9;
var tpl = this;
  var root = tpl.root;
  var buffer = tpl.buffer;
  var scope = tpl.scope;
  var runtime = tpl.runtime;
  var name = tpl.name;
  var pos = tpl.pos;
  var data = scope.data;
  var affix = scope.affix;
  var nativeCommands = root.nativeCommands;
  var utils = root.utils;
var callFnUtil = utils["callFn"];
var callDataFnUtil = utils["callDataFn"];
var callCommandUtil = utils["callCommand"];
var rangeCommand = nativeCommands["range"];
var voidCommand = nativeCommands["void"];
var foreachCommand = nativeCommands["foreach"];
var forinCommand = nativeCommands["forin"];
var eachCommand = nativeCommands["each"];
var withCommand = nativeCommands["with"];
var ifCommand = nativeCommands["if"];
var setCommand = nativeCommands["set"];
var includeCommand = nativeCommands["include"];
var includeOnceCommand = nativeCommands["includeOnce"];
var parseCommand = nativeCommands["parse"];
var extendCommand = nativeCommands["extend"];
var blockCommand = nativeCommands["block"];
var macroCommand = nativeCommands["macro"];
var debuggerCommand = nativeCommands["debugger"];
function func0(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n        <div class="menu-collapse" id="menuCollapse">\n          <img src="./assets/menuCollapse.svg"/>\n          <div class="divider"></div>\n        </div>\n      ';
return buffer;
}
function func7(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n          <img src="';
pos.line = 17;
var id8 = ((t=(affix.subLogo)) !== undefined ? t : ((t = data.subLogo) !== undefined ? t  : scope.resolveLooseUp(["subLogo"])));
buffer = buffer.writeEscaped(id8);
buffer.data += '" class="';
var id9 = ((t=(affix.logoClass)) !== undefined ? t : ((t = data.logoClass) !== undefined ? t  : scope.resolveLooseUp(["logoClass"])));
buffer = buffer.writeEscaped(id9);
buffer.data += '"/>\n        ';
return buffer;
}
function func10(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n          <img src="';
pos.line = 19;
var id11 = ((t=(affix.subLogo)) !== undefined ? t : ((t = data.subLogo) !== undefined ? t  : scope.resolveLooseUp(["subLogo"])));
buffer = buffer.writeEscaped(id11);
buffer.data += '" class="header-detail-logo"/>\n        ';
return buffer;
}
function func6(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n        <div class="divider"></div>\n        ';
pos.line = 16;
pos.line = 16;
var id12 = ((t=(affix.logoClass)) !== undefined ? t : ((t = data.logoClass) !== undefined ? t  : scope.resolveLooseUp(["logoClass"])));
buffer = ifCommand.call(tpl, scope, {  params: [ id12 ],fn: func7,inverse: func10 }, buffer);
buffer.data += '\n          <span class="header-detail-name">';
pos.line = 21;
var id13 = ((t=(affix.subName)) !== undefined ? t : ((t = data.subName) !== undefined ? t  : scope.resolveLooseUp(["subName"])));
buffer = buffer.writeEscaped(id13);
buffer.data += '</span>\n      ';
return buffer;
}
function func15(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n          <a class="menu" href="';
pos.line = 25;
var id16 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id16);
buffer.data += '">\n            <span>';
pos.line = 26;
var id17 = ((t=(affix.text)) !== undefined ? t : ((t = data.text) !== undefined ? t  : scope.resolveLooseUp(["text"])));
buffer = buffer.writeEscaped(id17);
buffer.data += '</span>\n          </a>\n        ';
return buffer;
}
function func20(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n                      <li class="list-item">\n                        <a href="';
pos.line = 47;
var id21 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id21);
buffer.data += '">\n                          <img src="./assets/';
pos.line = 48;
var id22 = ((t=(affix.logo)) !== undefined ? t : ((t = data.logo) !== undefined ? t  : scope.resolveLooseUp(["logo"])));
buffer = buffer.writeEscaped(id22);
buffer.data += '" />\n                          <p class="right">\n                            <span class="right-title">';
pos.line = 50;
var id23 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id23);
buffer.data += '</span>\n                            <span class="desc">';
pos.line = 51;
var id24 = ((t=(affix.desc)) !== undefined ? t : ((t = data.desc) !== undefined ? t  : scope.resolveLooseUp(["desc"])));
buffer = buffer.writeEscaped(id24);
buffer.data += '</span>\n                          </p>\n                        </a>\n                      </li>\n                    ';
return buffer;
}
function func19(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n                    ';
pos.line = 45;
pos.line = 45;
var id25 = ((t=(affix.tag)) !== undefined ? t : ((t = data.tag) !== undefined ? t  : scope.resolveLooseUp(["tag"])));
var exp26 = id25;
exp26 = (id25) === ('component');
buffer = ifCommand.call(tpl, scope, {  params: [ exp26 ],fn: func20 }, buffer);
buffer.data += '                    \n                  ';
return buffer;
}
function func29(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n                      <li class="list-item">\n                        <a href="';
pos.line = 65;
var id30 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id30);
buffer.data += '">\n                          <img src="./assets/';
pos.line = 66;
var id31 = ((t=(affix.logo)) !== undefined ? t : ((t = data.logo) !== undefined ? t  : scope.resolveLooseUp(["logo"])));
buffer = buffer.writeEscaped(id31);
buffer.data += '" />\n                          <p class="right">\n                            <span class="right-title">';
pos.line = 68;
var id32 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id32);
buffer.data += '</span>\n                            <span class="desc">';
pos.line = 69;
var id33 = ((t=(affix.desc)) !== undefined ? t : ((t = data.desc) !== undefined ? t  : scope.resolveLooseUp(["desc"])));
buffer = buffer.writeEscaped(id33);
buffer.data += '</span>\n                          </p>\n                        </a>\n                      </li>\n                    ';
return buffer;
}
function func28(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n                    ';
pos.line = 63;
pos.line = 63;
var id34 = ((t=(affix.tag)) !== undefined ? t : ((t = data.tag) !== undefined ? t  : scope.resolveLooseUp(["tag"])));
var exp35 = id34;
exp35 = (id34) === ('tool');
buffer = ifCommand.call(tpl, scope, {  params: [ exp35 ],fn: func29 }, buffer);
buffer.data += '                                     \n                  ';
return buffer;
}
function func38(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n                    <li class="list-item">\n                      <a href="';
pos.line = 91;
var id39 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id39);
buffer.data += '">\n                        <img src="./assets/';
pos.line = 92;
var id40 = ((t=(affix.logo)) !== undefined ? t : ((t = data.logo) !== undefined ? t  : scope.resolveLooseUp(["logo"])));
buffer = buffer.writeEscaped(id40);
buffer.data += '" />\n                        <div class="right">\n                          <span class="right-title">';
pos.line = 94;
var id41 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id41);
buffer.data += '</span>\n                          <span class="desc">';
pos.line = 95;
var id42 = ((t=(affix.desc)) !== undefined ? t : ((t = data.desc) !== undefined ? t  : scope.resolveLooseUp(["desc"])));
buffer = buffer.writeEscaped(id42);
buffer.data += '</span>\n                        </div>\n                      </a>\n                    </li>\n                  ';
return buffer;
}
function func37(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n                  ';
pos.line = 89;
pos.line = 89;
var id43 = ((t=(affix.tag)) !== undefined ? t : ((t = data.tag) !== undefined ? t  : scope.resolveLooseUp(["tag"])));
var exp44 = id43;
exp44 = (id43) === ('ecological');
buffer = ifCommand.call(tpl, scope, {  params: [ exp44 ],fn: func38 }, buffer);
buffer.data += '                    \n                ';
return buffer;
}
function func46(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n        <div class="divider menu-right-divider"></div>\n          <a href="';
pos.line = 107;
var id47 = ((t=(affix.helpdocs)) !== undefined ? t : ((t = data.helpdocs) !== undefined ? t  : scope.resolveLooseUp(["helpdocs"])));
buffer = buffer.writeEscaped(id47);
buffer.data += '">\n            <span class="menu-text menu-help-docs">\n              <span class="title title-help-docs">帮助文档</span>\n            </span>\n          </a>\n        ';
return buffer;
}
function func54(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n          <li class="nav-list-item">\n            <a href="';
pos.line = 133;
var id55 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id55);
buffer.data += '" target="_blank" rel="noopener noreferrer">\n              <img src="./assets/';
pos.line = 134;
var id56 = ((t=(affix.logo)) !== undefined ? t : ((t = data.logo) !== undefined ? t  : scope.resolveLooseUp(["logo"])));
buffer = buffer.writeEscaped(id56);
buffer.data += '" class="item-logo" >\n              <div>\n                <p class="item-title">';
pos.line = 136;
var id57 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id57);
buffer.data += '</p>\n                <p class="item-sub-title">';
pos.line = 137;
var id58 = ((t=(affix.desc)) !== undefined ? t : ((t = data.desc) !== undefined ? t  : scope.resolveLooseUp(["desc"])));
buffer = buffer.writeEscaped(id58);
buffer.data += '</p>\n              </div>\n            </a>\n          </li>\n          ';
return buffer;
}
function func53(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n          ';
pos.line = 131;
pos.line = 131;
var id59 = ((t=(affix.tag)) !== undefined ? t : ((t = data.tag) !== undefined ? t  : scope.resolveLooseUp(["tag"])));
var exp60 = id59;
exp60 = (id59) === ('component');
buffer = ifCommand.call(tpl, scope, {  params: [ exp60 ],fn: func54 }, buffer);
buffer.data += '     \n          ';
return buffer;
}
function func63(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n          <li class="nav-list-item">\n            <a href="';
pos.line = 149;
var id64 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id64);
buffer.data += '" target="_blank" rel="noopener noreferrer">\n              <img src="./assets/';
pos.line = 150;
var id65 = ((t=(affix.logo)) !== undefined ? t : ((t = data.logo) !== undefined ? t  : scope.resolveLooseUp(["logo"])));
buffer = buffer.writeEscaped(id65);
buffer.data += '" class="item-logo" >\n              <div>\n                <p class="item-title">';
pos.line = 152;
var id66 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id66);
buffer.data += '</p>\n                <p class="item-sub-title">';
pos.line = 153;
var id67 = ((t=(affix.desc)) !== undefined ? t : ((t = data.desc) !== undefined ? t  : scope.resolveLooseUp(["desc"])));
buffer = buffer.writeEscaped(id67);
buffer.data += '</p>\n              </div>\n            </a>\n          </li>\n          ';
return buffer;
}
function func62(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n          ';
pos.line = 147;
pos.line = 147;
var id68 = ((t=(affix.tag)) !== undefined ? t : ((t = data.tag) !== undefined ? t  : scope.resolveLooseUp(["tag"])));
var exp69 = id68;
exp69 = (id68) === ('tool');
buffer = ifCommand.call(tpl, scope, {  params: [ exp69 ],fn: func63 }, buffer);
buffer.data += ' \n        ';
return buffer;
}
function func72(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n              <li class="nav-list-item">\n                <a href="';
pos.line = 168;
var id73 = ((t=(affix.href)) !== undefined ? t : ((t = data.href) !== undefined ? t  : scope.resolveLooseUp(["href"])));
buffer = buffer.writeEscaped(id73);
buffer.data += '" target="_blank" rel="noopener noreferrer">\n                  <img src="./assets/';
pos.line = 169;
var id74 = ((t=(affix.logo)) !== undefined ? t : ((t = data.logo) !== undefined ? t  : scope.resolveLooseUp(["logo"])));
buffer = buffer.writeEscaped(id74);
buffer.data += '" class="item-logo" >\n                  <div>\n                    <p class="item-title">';
pos.line = 171;
var id75 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id75);
buffer.data += '</p>\n                    <p class="item-sub-title">';
pos.line = 172;
var id76 = ((t=(affix.desc)) !== undefined ? t : ((t = data.desc) !== undefined ? t  : scope.resolveLooseUp(["desc"])));
buffer = buffer.writeEscaped(id76);
buffer.data += '</p>\n                  </div>\n                </a>\n              </li>\n            ';
return buffer;
}
function func71(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n            ';
pos.line = 166;
pos.line = 166;
var id77 = ((t=(affix.tag)) !== undefined ? t : ((t = data.tag) !== undefined ? t  : scope.resolveLooseUp(["tag"])));
var exp78 = id77;
exp78 = (id77) === ('ecological');
buffer = ifCommand.call(tpl, scope, {  params: [ exp78 ],fn: func72 }, buffer);
buffer.data += '                    \n          ';
return buffer;
}
function func80(scope, buffer, undefined) {
    var data = scope.data;
    var affix = scope.affix;
buffer.data += '\n      <a href="';
pos.line = 182;
var id81 = ((t=(affix.helpdocs)) !== undefined ? t : ((t = data.helpdocs) !== undefined ? t  : scope.resolveLooseUp(["helpdocs"])));
buffer = buffer.writeEscaped(id81);
buffer.data += '" class="help-docs">帮助文档</a>\n      ';
return buffer;
}


buffer.data += '<div class="tinyui-design-header" id="tinyuiDesignHeader">\n  <div class="nav">\n    <div class="nav-left">\n      ';
pos.line = 4;
var id1 = ((t=(affix.menuCollapse)) !== undefined ? t : ((t = data.menuCollapse) !== undefined ? t  : scope.resolveLooseUp(["menuCollapse"])));
var exp3 = id1;
if((exp3)){
var id2 = ((t=(affix.menuCollapse)) !== undefined ? (t != null ? (t0 = t.useCollapse) : t) : ((t = data.menuCollapse) !== undefined ? (t != null ? (t0 = t.useCollapse) : t)  : scope.resolveLooseUp(["menuCollapse","useCollapse"])));
exp3 = id2;
}
buffer = ifCommand.call(tpl, scope, {  params: [ exp3 ],fn: func0 }, buffer);
buffer.data += '\n      <a class="nav-logo" href="';
pos.line = 10;
var id4 = ((t=(affix.logoLink)) !== undefined ? t : ((t = data.logoLink) !== undefined ? t  : scope.resolveLooseUp(["logoLink"])));
buffer = buffer.writeEscaped(id4);
buffer.data += '" title="';
var id5 = ((t=(affix.title)) !== undefined ? t : ((t = data.title) !== undefined ? t  : scope.resolveLooseUp(["title"])));
buffer = buffer.writeEscaped(id5);
buffer.data += '">\n        <img src="./assets/opentiny-logo.svg" class="header-logo"/>\n        <span class="header-name">OpenTiny</span>\n      </a>\n      ';
pos.line = 14;
pos.line = 14;
var id14 = ((t=(affix.subLogo)) !== undefined ? t : ((t = data.subLogo) !== undefined ? t  : scope.resolveLooseUp(["subLogo"])));
buffer = ifCommand.call(tpl, scope, {  params: [ id14 ],fn: func6 }, buffer);
buffer.data += '\n      <div class="nav-menus nav-menus-left">\n        ';
pos.line = 24;
pos.line = 24;
var id18 = ((t=(affix.menus)) !== undefined ? t : ((t = data.menus) !== undefined ? t  : scope.resolveLooseUp(["menus"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id18 ],fn: func15 }, buffer);
buffer.data += '\n      </div>\n    </div>\n\n    <div class="nav-right">\n      <div class="nav-menus">\n        <div class="menu menu-right menu-dev">\n          <span class="menu-text menu-text-dev">\n            <span class="title">开发</span>\n            <span class="menu-arrow"></span>\n          </span>\n          <div class="menu-dropdown">\n            <div class="content">\n              <div class="content-left">\n                <div class="title">开发文档</div>\n                <ul class="list">\n                  ';
pos.line = 44;
pos.line = 44;
var id27 = ((t=(affix.quickMenus)) !== undefined ? t : ((t = data.quickMenus) !== undefined ? t  : scope.resolveLooseUp(["quickMenus"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id27 ],fn: func19 }, buffer);
buffer.data += '       \n                </ul>\n              </div>\n              <div class="content-right">\n                <div class="title">开发资源</div>\n                <ul class="list">\n                  ';
pos.line = 62;
pos.line = 62;
var id36 = ((t=(affix.quickMenus)) !== undefined ? t : ((t = data.quickMenus) !== undefined ? t  : scope.resolveLooseUp(["quickMenus"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id36 ],fn: func28 }, buffer);
buffer.data += '     \n                </ul>\n              </div>\n            </div>\n          </div> \n        </div>\n        <div class="menu menu-right">\n          <span class="menu-text">\n          <span class="title">生态产品</span>\n          <span class="menu-arrow"></span>\n          </span>\n          <div class="menu-dropdown dropdown-production">\n            <div class="content menu-dropdown-content">\n              <ul class="list">\n                ';
pos.line = 88;
pos.line = 88;
var id45 = ((t=(affix.quickMenus)) !== undefined ? t : ((t = data.quickMenus) !== undefined ? t  : scope.resolveLooseUp(["quickMenus"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id45 ],fn: func37 }, buffer);
buffer.data += '      \n              </ul>\n            </div>\n          </div>\n        </div>\n        ';
pos.line = 105;
pos.line = 105;
var id48 = ((t=(affix.helpdocs)) !== undefined ? t : ((t = data.helpdocs) !== undefined ? t  : scope.resolveLooseUp(["helpdocs"])));
buffer = ifCommand.call(tpl, scope, {  params: [ id48 ],fn: func46 }, buffer);
buffer.data += '\n      </div>      \n      <a class="github-icon" href="';
pos.line = 114;
var id49 = ((t=(affix.codeUrl)) !== undefined ? t : ((t = data.codeUrl) !== undefined ? t  : scope.resolveLooseUp(["codeUrl"])));
buffer = buffer.writeEscaped(id49);
buffer.data += '" target="_blank" rel="noopener noreferrer">\n        <img class="github-img" src="./assets/github.svg" />\n      </a>\n      <div class="nav-menus user-nav-menus">\n      ';
pos.line = 118;
var callRet52
buffer = root.includeModule(scope, {  params: [ esDep52 ] }, buffer,tpl);
var id50 = callRet52;
buffer = buffer.writeEscaped(id50);
buffer.data += '   \n      </div>\n    </div>\n    <!--移动端菜单  start-->\n    <div class="collapse-menu">\n      <img src="./assets/collapse.svg"/>\n    </div>\n    <div class="collapse-nav">\n      <div class="nav-item">\n        <h2 class="title">开发</h2>\n        <p class="sub-title">开发文档</p>\n        <ul class="nav-list">\n        ';
pos.line = 130;
pos.line = 130;
var id61 = ((t=(affix.quickMenus)) !== undefined ? t : ((t = data.quickMenus) !== undefined ? t  : scope.resolveLooseUp(["quickMenus"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id61 ],fn: func53 }, buffer);
buffer.data += '  \n        </ul>\n        <p class="sub-title">开发资源</p>\n        <ul class="nav-list">\n        ';
pos.line = 146;
pos.line = 146;
var id70 = ((t=(affix.quickMenus)) !== undefined ? t : ((t = data.quickMenus) !== undefined ? t  : scope.resolveLooseUp(["quickMenus"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id70 ],fn: func62 }, buffer);
buffer.data += ' \n        </ul>\n      </div>\n      <div class="line"></div>\n      <div class="nav-item">\n        <h2 class="title">生态产品</h2>\n        <ul class="nav-list">\n          ';
pos.line = 165;
pos.line = 165;
var id79 = ((t=(affix.quickMenus)) !== undefined ? t : ((t = data.quickMenus) !== undefined ? t  : scope.resolveLooseUp(["quickMenus"])));
buffer = eachCommand.call(tpl, scope, {  params: [ id79 ],fn: func71 }, buffer);
buffer.data += '      \n        </ul>\n      </div>\n      <div class="line"></div>\n      ';
pos.line = 181;
pos.line = 181;
var id82 = ((t=(affix.helpdocs)) !== undefined ? t : ((t = data.helpdocs) !== undefined ? t  : scope.resolveLooseUp(["helpdocs"])));
buffer = ifCommand.call(tpl, scope, {  params: [ id82 ],fn: func80 }, buffer);
buffer.data += '     \n      <a class="github-icon" href="';
pos.line = 184;
var id83 = ((t=(affix.codeUrl)) !== undefined ? t : ((t = data.codeUrl) !== undefined ? t  : scope.resolveLooseUp(["codeUrl"])));
buffer = buffer.writeEscaped(id83);
buffer.data += '" target="_blank" rel="noopener noreferrer">GitHub</a>\n      ';
pos.line = 185;
var callRet86
buffer = root.includeModule(scope, {  params: [ esDep86 ] }, buffer,tpl);
var id84 = callRet86;
buffer = buffer.writeEscaped(id84);
buffer.data += '\n    </div>\n    <!--移动端菜单  end-->\n  </div>\n</div>\n';
return buffer;
};
  
  ret.TPL_NAME = 'header.xtpl';
  
  export default ret;